<template>
  <div>
    <TitleWithBackButton
      title="Impostazioni"
      back-route="Homepage"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <SettingsHome
      :style="{
        'pointer-events': canManageSettings ? 'all' : 'none',
        opacity: canManageSettings ? '1' : '0.2',
      }"
    ></SettingsHome>
    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Utente non abilitato"
      :error-message="errorMessage"
      @close-error="goBack"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import SettingsHome from '@/components/setting/SettingsHome.vue'
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";

export default {
  name: "Analytics",
  components: {
    TitleWithBackButton,
    SettingsHome,
    ErrorDialogComponent
  },
  data: function () {
    return {
      showErrorDialog: false,
      errorMessage: "",
      settingsPermission: [],
    };
  },  
  methods: {
    goBack() {
      this.$router.push({ name: "Homepage" });
    },
  },
  computed: {
    canManageSettings() {
      if (
        this.settingsPermission.includes("view")
      )
        return true;
      else {
        this.showErrorDialog = true;
        this.errorMessage = "Accesso alle impostazioni non autorizzato ";
        return false;
      }
    },
  },
  permissions: {
    Settings: {
      bind: "settingsPermission",
    },
  },
};
</script>

<style>
</style>